// Chakra imports
import {Flex, Grid, Text, useColorModeValue} from "@chakra-ui/react";
import ProfileBgImage from "assets/img/ProfileBackground.png";
import React, { useState } from "react";
import Header from "./components/Header";
import AllProducts from "./components/AllProducts";

function AllProductsIndex() {
  // Chakra color mode
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );
  const textColor = useColorModeValue("gray.700", "white");

  return (
      <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
        <Text
            color={textColor}
            fontWeight="bold"
            fontSize="3xl"
            mb="30px"
            ps="20px"
        >
            All Products
        </Text>

        <AllProducts title={""} description={""} />
    </Flex>
  );
}

export default AllProductsIndex;
