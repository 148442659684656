import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Image,
  ListItem,
  Progress,
  Select,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UnorderedList,
  useColorModeValue,
} from "@chakra-ui/react";
import ptz_1 from "./img/PTZ_1.jpg";
import tncBp516afs from "./img/TNC-BP516AFS.png";
import tncSpt2m36AF from "./img/TNC-SPT2M-36AF.png";
import tncVs210afs from "./img/TNC-VS210AFS.png";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useState } from "react";
import { BsStarFill, BsStarHalf } from "react-icons/bs";

function TncVs210afs() {
  const [currentImage, setCurrentImage] = useState(tncVs210afs);
  const textColor = useColorModeValue("gray.700", "white");

  return (
      <Card mt={{ sm: "125px", md: "75px" }}>
        <CardHeader mb="42px">
          <Text color={textColor} fontSize="lg" fontWeight="bold">
            Product Details
          </Text>
        </CardHeader>
        <CardBody>
          <Flex direction="column" w="100%">
            <Flex
                direction={{ sm: "column", lg: "row" }}
                mb={{ sm: "42px", lg: "84px" }}
            >
              <Flex
                  direction="column"
                  me={{ lg: "70px", xl: "120px" }}
                  mb={{ sm: "24px", lg: "0px" }}
              >
                <Box
                    w={{ sm: "275px", md: "670px", lg: "450px", xl: "600px" }}
                    h={{ sm: "200px", md: "500px", lg: "330px", xl: "500px" }}
                    mb="26px"
                    mx={{ sm: "auto", lg: "0px" }}
                >
                  <Image
                      src={currentImage}
                      w="100%"
                      h="100%"
                      borderRadius="15px"
                  />
                </Box>
                <Stack
                    direction="row"
                    spacing={{ sm: "20px", md: "35px", lg: "20px" }}
                    mx="auto"
                    mb={{ sm: "24px", lg: "0px" }}
                >
                  <Box
                      w={{ sm: "36px", md: "90px", lg: "60px" }}
                      h={{ sm: "36px", md: "90px", lg: "60px" }}
                  >
                    <Image
                        src={tncVs210afs}
                        w="100%"
                        h="100%"
                        borderRadius="15px"
                        cursor="pointer"
                        onClick={(e) => setCurrentImage(e.target.src)}
                    />
                  </Box>
                  <Box
                      w={{ sm: "36px", md: "90px", lg: "60px" }}
                      h={{ sm: "36px", md: "90px", lg: "60px" }}
                  >
                    <Image
                        src={ptz_1}
                        w="100%"
                        h="100%"
                        borderRadius="15px"
                        cursor="pointer"
                        onClick={(e) => setCurrentImage(e.target.src)}
                    />
                  </Box>
                </Stack>
              </Flex>
              <Flex direction="column">
                <Text
                    color={textColor}
                    fontSize="3xl"
                    fontWeight="bold"
                    mb="12px"
                >
                  TNC-VS210AFS
                </Text>

                <Flex direction="row">
                  <Badge
                      colorScheme="green"
                      w="95px"
                      h="28px"
                      mb="40px"
                      borderRadius="15px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                  >
                    Network
                  </Badge>
                  &nbsp;
                  <Badge
                      colorScheme="red"
                      w="95px"
                      h="28px"
                      mb="40px"
                      borderRadius="15px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                  >
                    IR
                  </Badge>
                  &nbsp;
                  <Badge
                      colorScheme="blue"
                      w="95px"
                      h="28px"
                      mb="40px"
                      borderRadius="15px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                  >
                    2M
                  </Badge>
                </Flex>


                <Text color="gray.400" fonSize="sm" fontWeight="normal" mb="8px">
                  Key Features
                </Text>
                <UnorderedList spacing="8px" mb="40px">
                  <ListItem fontSize="md" color={textColor} fontWeight="normal">
                    200만 화소 네트워크 PTZ 카메라(1920x1080P @ 60fps)
                  </ListItem>
                  <ListItem fontSize="md" color={textColor} fontWeight="normal">
                    H.265 / H.264 /MJPEG Triple 스트리밍 지원
                  </ListItem>
                  <ListItem fontSize="md" color={textColor} fontWeight="normal">
                    1/2” SONY CMOS 이미지 센서_IMX385
                  </ListItem>
                  <ListItem fontSize="md" color={textColor} fontWeight="normal">
                    WDR(Wide Dynamic Range)
                  </ListItem>
                  <ListItem fontSize="md" color={textColor} fontWeight="normal">
                    DNR(Digital Noise Reduction)
                  </ListItem>
                  <ListItem fontSize="md" color={textColor} fontWeight="normal">
                    프리셋 위치 지정 및 TOUR (옵션)
                  </ListItem>
                  <ListItem fontSize="md" color={textColor} fontWeight="normal">
                    파워 온 액션,오토 파킹 지원
                  </ListItem>
                  <ListItem fontSize="md" color={textColor} fontWeight="normal">
                    양뱡향 오디오 지원 및 SDCARD (OPTION)
                  </ListItem>
                  <ListItem fontSize="md" color={textColor} fontWeight="normal">
                    W / Bracket
                  </ListItem>
                  <ListItem fontSize="md" color={textColor} fontWeight="normal">
                    IP66
                  </ListItem>
                </UnorderedList>


                <Button
                    variant="no-hover"
                    bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
                    w={{sm: "240px", md: "100%", lg: "240px"}}
                    h="50px"
                    mx={{sm: "auto", md: "0px"}}
                    color="#fff"
                    fontSize="xs"
                    fontWeight="bold"
                >
                  <a href={"/#/support"}>
                    Download Manual
                  </a>
                </Button>
                &nbsp;
                <Button
                    variant="no-hover"
                    bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
                    w={{sm: "240px", md: "100%", lg: "240px"}}
                    h="50px"
                    mx={{sm: "auto", md: "0px"}}
                    color="#fff"
                    fontSize="xs"
                    fontWeight="bold"
                >
                  <a href="/software/Setup_IPManager_v2.1.0.8.exe.xz" download>
                    Download Ip Manager
                  </a>
                </Button>
              </Flex>
            </Flex>

            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Product Spec
            </Text>

            <Box w="100%" overflowX={{ sm: "scroll", lg: "hidden" }}>
              <Table variant="simple" w="100%">
                <Thead>
                  <Tr>
                    <Th color="gray.400" fontSize="xs">
                      Feature
                    </Th>
                    <Th color="gray.400" fontSize="xs">
                      TNC-VS210AFS
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">유효화소수</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">1944(H) x 1092(V) = 2.12M Pixels</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">방송방식</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">NTSC Standard: 525 Line, 30Frames/Sec</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">촬상소자</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">2.0 Mega Pixels 1/2” SONY CMOS 이미지센서</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">최소거리</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">10mm ~ 1000mm</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">S/N비</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">50dB</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">최저조도</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">0Lux(IR ON) Built in Super IR 20m</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">렌즈 줌 배율</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">X3.0</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">초점 거리</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">3.6~11mm // 6~22mmAF</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">구경비</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">F1.6(wide) ~ F3.5(tele)</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">화각 (D / H / V)</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Wide 62.7° / 55.4° / 32.6°, Tele 3.3° / 2.9° / 1.6°</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">압축방식</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Main Stream: H.265/H.264, Secondary Stream: H.264/MPEG-4/MJPEG</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">전송 프레임</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">QUAD Streaming H.264 / MPEG-4 / MJPEG</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">입력</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">1Line-In</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">출력</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">1Line-Out</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">프리셋 설정 수</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">190개</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">프리셋 속도 가변 지정</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">저속, 중속, 고속 (360°/Sec)</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">프리셋 이름 개별 설정 기능</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">On/Off</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">프리셋 Pan/Tilt속도</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Pan 0~355°, Tilt 0~90°/Sec가변설정가능</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">회전 동작 범위</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">0°~355° 연속회전(Pan) / 0°~90°(Tilt)</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">최대 회전 속도</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Pan 355° / Sec</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">충격 감지 센서 (option)</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">x</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">전원On/Off 데이터 제어</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Yes(OSD Menu)</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Scan</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">x</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">패턴 기능</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">x</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">스윙, Preset 위치 설정</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Yes</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">센서 입/출력(option)</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">1 Input  Output1</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">정전복구 기능</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">마지막 위치 기억</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Group 기능</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">x</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Tour, Vector, 줌 속도 기능</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">x</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">데이터 메모리 백업 기능(option)</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Yes</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">FAN (option)</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">x</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">인증서</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">YES</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">특정 영역 차단 기능(사생활보호)</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">8개 영역 (On/Off)</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">절대좌표 (방위각)표출</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">1°~355° 방위 자동 표출 기능(On/Off)</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Compression</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">H.265/H.264 / MPEG-4 / MJPEG Triple Streaming</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Resolution</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">1080P, 720P, 4CIF, VGA, CIF, QVGA, QCIF, QQVGA</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Bit Rate</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">32Kbps ~ 20Mbps</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Frame Rate</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">60fps up to 1080p, 30/25(NTSC/PAL)fps @ all Resolution</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Ethernet</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Ethernet 10/100 Base-T(RJ-45)</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Audio</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Two way full duplex G.711 / G.726</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Protocol</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">TCP,IP, UDP, HTTP, DHCP, PPPoE, FTP, DNS, DDNS, NTP, ICMP, SNMP, SMTPE, UPnP, RTP, RTSP, ONVIF, GENETEC</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">ROI Encording</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Yes</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Home Position 기능</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Yes</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">자체진단기능</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Yes</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">OSD</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Yes</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">동작온도/습도</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">-10°C ~ 50°C / 0 ~ 90RH % 이하</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">재질</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Dicasting (Body), 아크릴(Cover)</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">사용 전원</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">DC12V 2A 60Hz PoE(40V인젝트지원)</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">중량</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">450g (±1%)</Text> </Td>
                  </Tr>
                  <Tr>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">외형 치수</Text> </Td>
                    <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">130mm(Ø) x 117(H) mm</Text> </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>


            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Other Products
            </Text>
            <Box w="100%" overflowX={{ sm: "scroll", lg: "hidden" }}>
              <Table variant="simple" w="100%">
                <Thead>
                  <Tr>
                    <Th color="gray.400" fontSize="xs">
                      Model Name
                    </Th>
                    <Th color="gray.400" fontSize="xs">
                    </Th>
                    <Th color="gray.400" fontSize="xs">
                    </Th>
                    <Th color="gray.400" fontSize="xs">
                    </Th>
                    <Th color="gray.400" fontSize="xs">
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td minW="300px">
                      <Flex align="center">
                        <Box w="40px" h="40px" me="14px">
                          <Image
                              src={tncBp516afs}
                              w="100%"
                              h="100%"
                              borderRadius="12px"
                          />
                        </Box>
                        <a href="/#/product/ptz/tnc-bp516afs">
                          <Text color={textColor} fontSize="sm" fontWeight="bold">
                            TNC-BP516AFS
                          </Text>
                        </a>
                      </Flex>
                    </Td>
                    <Td>
                      <Text color="gray.500" fontSize="sm" fontWeight="bold">
                        Network
                      </Text>
                    </Td>
                    <Td>
                      <Text color="gray.500" fontSize="sm" fontWeight="bold">
                        IR
                      </Text>
                    </Td>
                    <Td>
                      <Text color="gray.500" fontSize="sm" fontWeight="bold">
                        2M
                      </Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td minW="300px">
                      <Flex align="center">
                        <Box w="40px" h="40px" me="14px">
                          <Image
                              src={tncSpt2m36AF}
                              w="100%"
                              h="100%"
                              borderRadius="12px"
                          />
                        </Box>
                        <a href="/#/product/ptz/itvs-spt2m-36af">
                          <Text color={textColor} fontSize="sm" fontWeight="bold">
                            ITVS-SPT2M-36AF
                          </Text>
                        </a>
                      </Flex>
                    </Td>
                    <Td>
                      <Text color="gray.500" fontSize="sm" fontWeight="bold">
                        Network
                      </Text>
                    </Td>
                    <Td>
                      <Text color="gray.500" fontSize="sm" fontWeight="bold">
                        IR
                      </Text>
                    </Td>
                    <Td>
                      <Text color="gray.500" fontSize="sm" fontWeight="bold">
                        2M
                      </Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td minW="300px">
                      <Flex align="center">
                        <Box w="40px" h="40px" me="14px">
                          <Image
                              src={tncSpt2m36AF}
                              w="100%"
                              h="100%"
                              borderRadius="12px"
                          />
                        </Box>
                        <a href="/#/product/ptz/tnc-bp21v08afs">
                          <Text color={textColor} fontSize="sm" fontWeight="bold">
                            TNC-BP21V08AFS
                          </Text>
                        </a>
                      </Flex>
                    </Td>
                    <Td>
                      <Text color="gray.500" fontSize="sm" fontWeight="bold">
                        Network
                      </Text>
                    </Td>
                    <Td>
                      <Text color="gray.500" fontSize="sm" fontWeight="bold">
                        IR
                      </Text>
                    </Td>
                    <Td>
                      <Text color="gray.500" fontSize="sm" fontWeight="bold">
                        2M
                      </Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td minW="300px">
                      <Flex align="center">
                        <Box w="40px" h="40px" me="14px">
                          <Image
                              src={tncVs210afs}
                              w="100%"
                              h="100%"
                              borderRadius="12px"
                          />
                        </Box>
                        <a href="/#/product/ptz/tnc-vs210af">
                          <Text color={textColor} fontSize="sm" fontWeight="bold">
                            TNC-VS210AF
                          </Text>
                        </a>
                      </Flex>
                    </Td>
                    <Td>
                      <Text color="gray.500" fontSize="sm" fontWeight="bold">
                        Network
                      </Text>
                    </Td>
                    <Td>
                      <Text color="gray.500" fontSize="sm" fontWeight="bold">
                        IR
                      </Text>
                    </Td>
                    <Td>
                      <Text color="gray.500" fontSize="sm" fontWeight="bold">
                        2M
                      </Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td minW="300px">
                      <Flex align="center">
                        <Box w="40px" h="40px" me="14px">
                          <Image
                              src={tncVs210afs}
                              w="100%"
                              h="100%"
                              borderRadius="12px"
                          />
                        </Box>
                        <a href="/#/product/ptz/tnc-vs510afs">
                          <Text color={textColor} fontSize="sm" fontWeight="bold">
                            TNC-VS510AFS
                          </Text>
                        </a>
                      </Flex>
                    </Td>
                    <Td>
                      <Text color="gray.500" fontSize="sm" fontWeight="bold">
                        Network
                      </Text>
                    </Td>
                    <Td>
                      <Text color="gray.500" fontSize="sm" fontWeight="bold">
                        IR
                      </Text>
                    </Td>
                    <Td>
                      <Text color="gray.500" fontSize="sm" fontWeight="bold">
                        5M
                      </Text>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>

          </Flex>
        </CardBody>
      </Card>
  );
}

export default TncVs210afs;
