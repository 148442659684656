// Chakra imports
import {
  Avatar,
  Box,
  Button,
  Flex,
  Text,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useReducer, useState} from "react";
import { FaCube, FaPenFancy } from "react-icons/fa";
import { IoDocumentsSharp } from "react-icons/io5";


const reducer = (state, action) => {
  if (action.type === "SWITCH_ACTIVE") {
    if (action.payload === "overview") {
      const newState = {
        overview: true,
        teams: false,
        projects: false,
      };
      return newState;
    } else if (action.payload === "teams") {
      const newState = {
        overview: false,
        teams: true,
        projects: false,
      };
      return newState;
    } else if (action.payload === "projects") {
      const newState = {
        overview: false,
        teams: false,
        projects: true,
      };
      return newState;
    }
  }
  return state;
};

const Header = ({
                  backgroundHeader,
                  backgroundProfile,
                  name,
                  email,
                }) => {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const borderProfileColor = useColorModeValue(
      "white",
      "rgba(255, 255, 255, 0.31)"
  );
  const emailColor = useColorModeValue("gray.400", "gray.300");

  const [state, dispatch] = useReducer(reducer, {
    overview: true,
    teams: false,
    projects: false,
  });
  return (
      <Box
          mb={{ sm: "205px", md: "75px", xl: "70px" }}
          borderRadius="15px"
          px="0px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          align="center"
      >
        <Box
            bgImage={backgroundHeader}
            w="100%"
            h="300px"
            borderRadius="25px"
            bgPosition="50%"
            bgRepeat="no-repeat"
            position="relative"
            display="flex"
            justifyContent="center"
        >
        </Box>
      </Box>
  );
};

export default Header;
