import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Image,
  ListItem,
  Progress,
  Select,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UnorderedList,
  useColorModeValue,
} from "@chakra-ui/react";
import tncBz236v12afImg from "./img/TNC-BZ236V12AF.png";
import tncB210v12afsImg from "./img/TNC-B210V12AFS.png";
import tncB217v42af from "./img/TNC-B217-V42AF.png";
import tncBz204v12afsImg from "./img/TNC-BZ204V12AFS.png";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useState } from "react";
import { BsStarFill, BsStarHalf } from "react-icons/bs";

function TncB210v12afs() {
  const [currentImage, setCurrentImage] = useState(tncB210v12afsImg);
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Card mt={{ sm: "125px", md: "75px" }}>
      <CardHeader mb="42px">
        <Text color={textColor} fontSize="lg" fontWeight="bold">
          Product Details
        </Text>
      </CardHeader>
      <CardBody>
        <Flex direction="column" w="100%">
          <Flex
            direction={{ sm: "column", lg: "row" }}
            mb={{ sm: "42px", lg: "84px" }}
          >
            <Flex
              direction="column"
              me={{ lg: "70px", xl: "120px" }}
              mb={{ sm: "24px", lg: "0px" }}
            >
              <Box
                w={{ sm: "275px", md: "670px", lg: "450px", xl: "600px" }}
                h={{ sm: "200px", md: "500px", lg: "330px", xl: "500px" }}
                mb="26px"
                mx={{ sm: "auto", lg: "0px" }}
              >
                <Image
                  src={currentImage}
                  w="100%"
                  h="100%"
                  borderRadius="15px"
                />
              </Box>
              <Stack
                direction="row"
                spacing={{ sm: "20px", md: "35px", lg: "20px" }}
                mx="auto"
                mb={{ sm: "24px", lg: "0px" }}
              >
                <Box
                  w={{ sm: "36px", md: "90px", lg: "60px" }}
                  h={{ sm: "36px", md: "90px", lg: "60px" }}
                >
                  <Image
                    src={tncB210v12afsImg}
                    w="100%"
                    h="100%"
                    borderRadius="15px"
                    cursor="pointer"
                    onClick={(e) => setCurrentImage(e.target.src)}
                  />
                </Box>
                {/*<Box*/}
                {/*  w={{ sm: "36px", md: "90px", lg: "60px" }}*/}
                {/*  h={{ sm: "36px", md: "90px", lg: "60px" }}*/}
                {/*>*/}
                {/*  <Image*/}
                {/*    src={productPage2}*/}
                {/*    w="100%"*/}
                {/*    h="100%"*/}
                {/*    borderRadius="15px"*/}
                {/*    cursor="pointer"*/}
                {/*    onClick={(e) => setCurrentImage(e.target.src)}*/}
                {/*  />*/}
                {/*</Box>*/}
              </Stack>
            </Flex>
            <Flex direction="column">
              <Text
                color={textColor}
                fontSize="3xl"
                fontWeight="bold"
                mb="12px"
              >
                TNC-B210V12AFS
              </Text>
              <Flex direction="row">
                <Badge
                    colorScheme="green"
                    w="95px"
                    h="28px"
                    mb="40px"
                    borderRadius="15px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                  Network
                </Badge>
                &nbsp;
                <Badge
                    colorScheme="red"
                    w="95px"
                    h="28px"
                    mb="40px"
                    borderRadius="15px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                  IR
                </Badge>
                &nbsp;
                <Badge
                    colorScheme="blue"
                    w="95px"
                    h="28px"
                    mb="40px"
                    borderRadius="15px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                  2M
                </Badge>
              </Flex>

              <Text color="gray.400" fonSize="sm" fontWeight="normal" mb="8px">
                Key Features
              </Text>
              <UnorderedList spacing="8px" mb="40px">
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  200만 화소 네트워크 카메라(1920x1080P @ 60fps)
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  H.265 / H.264 / MJPEG  Triple 스트리밍 지원
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  1/2” SONY CMOS 이미지 센서_IMX385
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  Built In AF ZOOM LENS (3.6~12mmAF)
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  IR 제어 (850nm) +White led
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  움직임 감지설정동작 및 외부 Sensor 감지시 만 White led 추가 동작 기능
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  양 뱡향 오디오 지원 ,ALARM IN &OUT
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  FAN 내장 (내부 온도설정에 따른 자동 동작)
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  IP66
                </ListItem>
              </UnorderedList>
              <Flex direction="row">

                <Button
                    variant="no-hover"
                    bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
                    w={{sm: "240px", md: "100%", lg: "240px"}}
                    h="50px"
                    mx={{sm: "auto", md: "0px"}}
                    color="#fff"
                    fontSize="xs"
                    fontWeight="bold"
                >
                  <a href={"/#/support"}>
                    Download Manual
                  </a>
                </Button>
                &nbsp;
                <Button
                    variant="no-hover"
                    bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
                    w={{sm: "240px", md: "100%", lg: "240px"}}
                    h="50px"
                    mx={{sm: "auto", md: "0px"}}
                    color="#fff"
                    fontSize="xs"
                    fontWeight="bold"
                >
                  <a href="/software/Setup_IPManager_v2.1.0.8.exe.xz" download>
                    Download Ip Manager
                  </a>
                </Button>
              </Flex>
            </Flex>
          </Flex>

          <Text color={textColor} fontSize="lg" fontWeight="bold">
            Product Spec
          </Text>

          <Box w="100%" overflowX={{ sm: "scroll", lg: "hidden" }}>
            <Table variant="simple" w="100%">
              <Thead>
                <Tr>
                  <Th color="gray.400" fontSize="xs">
                    Feature
                  </Th>
                  <Th color="gray.400" fontSize="xs">
                    TNC-B210V12AFS
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">유효화소</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">1920(H) x 1080(V)(Full-HD)</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">촬상소자</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">1/2" 2.1M Pixels Progressive Exmor Sensor</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">최소거리</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">100mm~1200mm</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">S/N비</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">54dB</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">최저조도</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">0 Lux (IR LED ON)</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">초점 거리</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">f=3.6mm ~ 12mm AF / 6.0~22mmAF</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">초점 제어</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">자동/수동 (네트워크를 통한 원격제어)</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">자동초점 (AuTo Exposure)</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">YES</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">디지털 줌</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">8x</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">OSD</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Off/ON</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Flip/Mirror</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Off/On</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">노출(AE)</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">자동/수동</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">셔터속도</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">1/10000 ~ 1/30 (sec)</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">자동 조리개</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Off/On</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">AuTo Flicker-less</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Off/On</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">색 온도(AWB)</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">자동(실내,실외), 그늘, 쾌청한 하늘, 형광등, 백열등, 불꽃, 수동</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Day & NighT</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">자동/주간/야간/외부신호</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">WDR,BLC,HLC,DNR</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Off/On</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">안개보정(Defog)</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Off/On/AuTo</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">세로모드</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Off/On (90도, 270도)</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">LDC 경계선</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Off/On</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">사생활 보호(Privacy Mask)</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">8개 영역 지정가능</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">움직임 감지(MoTion DeTecTion)</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">4개 Mask, 4개 DeTecTion 지정가능</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">이벤트 감지</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">알람 입력,부팅감지,수동 트리거,네트워크감지</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">이벤트 동작</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">알람 출력</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">화면 가림 감지(Temper)</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Off/On</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">야간가시거리</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">70~120m(ISUPER R LED : 8개) WHITE LED 2~4개</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">비디오 압축방식</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">H.264/H265(High, Main ,Baseline Profile), MJPEG</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">비디오 해상도</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">1920x1080, 1280x1024, 1280x720, 704x576, 704x480, 640x480, 640x360, 320x240</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">최대 프레임 레이트</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">최대 60fps@ 1920x1080</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">오디오 압축방식</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">G.711 ADPCM a-Law/u-Law</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">통신 양방향 입력(opTion)</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">1Line-In</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">통신 양방향 출력(opTion)</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">1Line-OuT</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">네트 워크 이더넷</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">RJ-45(10/100BASE-T)</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">네트 워크 비트 레이트</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">100 ~ 12000Kbps</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">네트 워크 IP</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">IPv4, IPv6</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">네트 워크 ProTocol</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">TCP/IP,UDP/IP,RTP,RTSP,HTTP,HTTPS,SMTP,FTP,DHCP,UPnP ,Zeroconf, DNS,DDNS</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">보안</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">HTTPS암호화,사용자 관리, IP주소 필터링</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">FTP Uploading</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">MJPEG STil Image</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">최대 동시 접속자</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">10</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">S/W 응용 인터페이스</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Onvif  profile S, ...</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">웹 뷰어 지원OS</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Windows VisTa(32biT)/7/8</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">웹 뷰어 지원 웹브라우져</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Micro InTerneT Explorer Ver.9, 10<br/>
                    Apple Safari Ver.4.0(Plug-in free viewer only)<br/>
                    Google Chrome Ver.4.0(Plug-in free viewer only)</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">웹 언어</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">한국어, English, 러시아어,일본어</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">일반 동작온도/습도</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">-20°C ~ 50°C / 90% RH 이하</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">일반 방진/방수</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">IP66</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">일반 조절</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Y</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">일반 사용전원/소비전류</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">DC12V, PoE(IEEE802.3af) / 1A(Max)</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">일반 재질/색상</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Aluminum / Dark gray / white</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">일반 중량</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">1.5 Kg</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">일반 외 형 치 수</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">85.7mm(W) X93mm(H)X297 mm(L)_75pi</Text> </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>

          <br/>

          <Text color={textColor} fontSize="lg" fontWeight="bold">
            Other Products
          </Text>

          <Box w="100%" overflowX={{ sm: "scroll", lg: "hidden" }}>
            <Table variant="simple" w="100%">
              <Thead>
                <Tr>
                  <Th color="gray.400" fontSize="xs">
                    Model Name
                  </Th>
                  <Th color="gray.400" fontSize="xs">
                  </Th>
                  <Th color="gray.400" fontSize="xs">
                  </Th>
                  <Th color="gray.400" fontSize="xs">
                  </Th>
                  <Th color="gray.400" fontSize="xs">
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td minW="300px">
                    <Flex align="center">
                      <Box w="40px" h="40px" me="14px">
                        <Image
                            src={tncBz236v12afImg}
                            w="100%"
                            h="100%"
                            borderRadius="12px"
                        />
                      </Box>
                      <a href="/#/product/bullet/tnc-bz236v12af">
                      <Text color={textColor} fontSize="sm" fontWeight="bold">
                        TNC-BZ236V12AF
                      </Text>
                      </a>
                    </Flex>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      Network
                    </Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      IR
                    </Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      2M
                    </Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td minW="300px">
                    <Flex align="center">
                      <Box w="40px" h="40px" me="14px">
                        <Image
                            src={tncBz204v12afsImg}
                            w="100%"
                            h="100%"
                            borderRadius="12px"
                        />
                      </Box>
                      <a href="/#/product/bullet/tnc-bz204v12afs">
                        <Text color={textColor} fontSize="sm" fontWeight="bold">
                          TNC-BZ204V12AFS
                        </Text>
                      </a>
                    </Flex>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      Network
                    </Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      IR
                    </Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      2M
                    </Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td minW="300px">
                    <Flex align="center">
                      <Box w="40px" h="40px" me="14px">
                        <Image
                            src={tncB210v12afsImg}
                            w="100%"
                            h="100%"
                            borderRadius="12px"
                        />
                      </Box>
                      <a href="/#/product/bullet/tnc-b510v12af">
                        <Text color={textColor} fontSize="sm" fontWeight="bold">
                          TNC-B510V12AF
                        </Text>
                      </a>
                    </Flex>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      Network
                    </Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      IR
                    </Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      5M
                    </Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td minW="300px">
                    <Flex align="center">
                      <Box w="40px" h="40px" me="14px">
                        <Image
                            src={tncB210v12afsImg}
                            w="100%"
                            h="100%"
                            borderRadius="12px"
                        />
                      </Box>
                      <a href="/#/product/bullet/tnc-b120v12afs">
                        <Text color={textColor} fontSize="sm" fontWeight="bold">
                          TNC-B120V12AFS
                        </Text>
                      </a>
                    </Flex>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      Network
                    </Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      IR
                    </Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      12M
                    </Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td minW="300px">
                    <Flex align="center">
                      <Box w="40px" h="40px" me="14px">
                        <Image
                            src={tncB217v42af}
                            w="100%"
                            h="100%"
                            borderRadius="12px"
                        />
                      </Box>
                      <a href="/#/product/bullet/utc-8060r">
                        <Text color={textColor} fontSize="sm" fontWeight="bold">
                          UTC-8060R
                        </Text>
                      </a>
                    </Flex>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      UTC
                    </Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      IR
                    </Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      4K 8M
                    </Text>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        </Flex>
      </CardBody>
    </Card>
  );
}

export default TncB210v12afs;
