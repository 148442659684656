/*global kakao*/

import {Flex, Input, SimpleGrid, Text, useColorModeValue} from "@chakra-ui/react";
// Custom components
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
// Custom icons
import {
  CartIcon,
  RocketIcon,
  StatsIcon,
  WalletIcon,
} from "../../../components/Icons/Icons.js";
import React, {useEffect} from "react";
import ChartStatistics from "./ChartStatistics";

const Location = () => {
  const iconBoxInside = useColorModeValue("white", "white");
  const textColor = useColorModeValue("gray.700", "white");
  const whiteTextColor = useColorModeValue("white", "white");

  const KakaoMap=({ title, lat, lng })=> {
    useEffect(() => {
      var mapContainer = document.getElementById('map'), // 지도를 표시할 div
          mapOption = {
            center: new kakao.maps.LatLng(lat, lng), // 지도의 중심좌표
            level: 3 // 지도의 확대 레벨
          };

      var map = new kakao.maps.Map(mapContainer, mapOption); // 지도를 생성합니다

      var markerPosition  = new kakao.maps.LatLng(lat, lng);
      var marker = new kakao.maps.Marker({
        position: markerPosition
      });

      marker.setMap(map);

    }, [])

    return (
        <Card
            py="1rem"
            // height={{ sm: "450px" }}
            width="100%"
            position="relative"
        >
          <div id="map" style={{width:"100%", height:"400px"}}></div>
        </Card>
    )
  }

  return (
    <Card p="16px">
      <CardBody>
        <Flex direction="column" w="100%">

          <Flex direction="column" mt="24px" mb="36px" alignSelf="flex-start">
            <Text fontSize="lg" color={textColor} fontWeight="bold" mb="6px">
              If you have a question, please contact to us.
            </Text>
            <Text fontSize="md" fontWeight="medium" color="gray.400">
              <Text
                as="span"
                color={"green.400"}
                fontWeight="bold"
              >
              </Text>{" "}
              We will respond quickly.
            </Text>
          </Flex>


          <Card
              py="1rem"
              width="100%"
              bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
              position="relative"
          >
            <Text fontSize="lg" color={whiteTextColor} fontWeight="bold" mb="6px">
              Korea
            </Text>
            <Text fontSize="sm" color={whiteTextColor} mb="6px">
              <li><i className="fa fa-home"></i>  236, Jubuto-ro, Bupyeong-gu, Incheon, Korea (Techno Valley U1 Center) C-807</li>
            </Text>
            <Text fontSize="sm" color={whiteTextColor} mb="6px">
              <li><i className="fa fa-home"></i>  인천광역시 부평구 주부토로 236 테크노밸리U1센터 C동 807호 </li>
            </Text>
            <Text fontSize="sm" color={whiteTextColor} mb="6px">
              <li><i className="fa fa-phone"></i>  +82 10 6309 7346</li>
            </Text>
            <Text fontSize="sm" color={whiteTextColor} mb="6px">
              <li><a href="mailto:tgcam@naver.com"><i className="fa fa-envelope"></i>tgcam@naver.com</a></li>
            </Text>

            <br/>
            <Text fontSize="lg" color={whiteTextColor} fontWeight="bold" mb="6px">
             China
            </Text>
            <Text fontSize="sm" color={whiteTextColor} mb="6px">
              <li><i className="fa fa-home"></i>  801F,NO.2, xinweicun xili Nanshan district Shenzhen city,Guangdong
                province,China(518055)
              </li>
            </Text>
            <Text fontSize="sm" color={whiteTextColor} mb="6px">
              <li><i className="fa fa-home"></i>  南山区西丽街道新围村2号楼801室</li>
            </Text>
            <Text fontSize="sm" color={whiteTextColor} mb="6px">
              <li><i className="fa fa-phone"></i>  +86 18682370991</li>
            </Text>
            <Text fontSize="sm" color={whiteTextColor} mb="6px">
              <li><a href="mailto:leeguohang@126.com"><i className="fa fa-envelope"></i>leeguohang@126.com</a></li>
            </Text>

          </Card>

          <KakaoMap
              id="tgcam"
              lat={37.5158580433375}
              lng={126.726800852864}
          />
        </Flex>

      </CardBody>
    </Card>
  );
};

export default Location;
