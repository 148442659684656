import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Image,
  ListItem,
  Progress,
  Select,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UnorderedList,
  useColorModeValue,
} from "@chakra-ui/react";
import tncBz236v12afImg from "./img/TNC-BZ236V12AF.png";
import tncB210v12afsImg from "./img/TNC-B210V12AFS.png";
import tncB217v42af from "./img/TNC-B217-V42AF.png";
import tncBz204v12afsImg from "./img/TNC-BZ204V12AFS.png";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useState } from "react";
import { BsStarFill, BsStarHalf } from "react-icons/bs";

function Utc8060r() {
  const [currentImage, setCurrentImage] = useState(tncB217v42af);
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Card mt={{ sm: "125px", md: "75px" }}>
      <CardHeader mb="42px">
        <Text color={textColor} fontSize="lg" fontWeight="bold">
          Product Details
        </Text>
      </CardHeader>
      <CardBody>
        <Flex direction="column" w="100%">
          <Flex
            direction={{ sm: "column", lg: "row" }}
            mb={{ sm: "42px", lg: "84px" }}
          >
            <Flex
              direction="column"
              me={{ lg: "70px", xl: "120px" }}
              mb={{ sm: "24px", lg: "0px" }}
            >
              <Box
                w={{ sm: "275px", md: "670px", lg: "450px", xl: "600px" }}
                h={{ sm: "200px", md: "500px", lg: "330px", xl: "500px" }}
                mb="26px"
                mx={{ sm: "auto", lg: "0px" }}
              >
                <Image
                  src={currentImage}
                  w="100%"
                  h="100%"
                  borderRadius="15px"
                />
              </Box>
              <Stack
                direction="row"
                spacing={{ sm: "20px", md: "35px", lg: "20px" }}
                mx="auto"
                mb={{ sm: "24px", lg: "0px" }}
              >
                <Box
                  w={{ sm: "36px", md: "90px", lg: "60px" }}
                  h={{ sm: "36px", md: "90px", lg: "60px" }}
                >
                  <Image
                    src={tncB217v42af}
                    w="100%"
                    h="100%"
                    borderRadius="15px"
                    cursor="pointer"
                    onClick={(e) => setCurrentImage(e.target.src)}
                  />
                </Box>
                {/*<Box*/}
                {/*  w={{ sm: "36px", md: "90px", lg: "60px" }}*/}
                {/*  h={{ sm: "36px", md: "90px", lg: "60px" }}*/}
                {/*>*/}
                {/*  <Image*/}
                {/*    src={productPage2}*/}
                {/*    w="100%"*/}
                {/*    h="100%"*/}
                {/*    borderRadius="15px"*/}
                {/*    cursor="pointer"*/}
                {/*    onClick={(e) => setCurrentImage(e.target.src)}*/}
                {/*  />*/}
                {/*</Box>*/}
              </Stack>
            </Flex>
            <Flex direction="column">
              <Text
                color={textColor}
                fontSize="3xl"
                fontWeight="bold"
                mb="12px"
              >
                UTC-8060R
              </Text>
              <Flex direction="row">
                <Badge
                    colorScheme="red"
                    w="95px"
                    h="28px"
                    mb="40px"
                    borderRadius="15px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                  IR
                </Badge>
                &nbsp;
                <Badge
                    colorScheme="blue"
                    w="95px"
                    h="28px"
                    mb="40px"
                    borderRadius="15px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                  4K 8M
                </Badge>
                &nbsp;
                <Badge
                    colorScheme="yellow"
                    w="95px"
                    h="28px"
                    mb="40px"
                    borderRadius="15px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                  UTC
                </Badge>
              </Flex>

              <Text color="gray.400" fonSize="sm" fontWeight="normal" mb="8px">
                Key Features
              </Text>
              <UnorderedList spacing="8px" mb="40px">
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  4K 고해상도  HD IR 카메라 (3840x2160 15fps)
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  1/2.5 “8M Pixels Progressive Exmor Sensor
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  F=3.6mm
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  Multi  해상도 지원 (2M/4M/5M/8M)
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  AHD (TVI/CVI/CVBS)
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  D-WDR 지원
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  Privacy Mask 8 Area 기능
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  2D, 3D 잡음제거 설정 가능
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  SMART IR 설정 지원
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  UTC(UP TO COAX)
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  OSD 제어 케이블 (옵션)
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  PIR 기능지원 (IRLED & WHITE LED) (옵션)
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  IP66
                </ListItem>
              </UnorderedList>
              <Flex direction="row">

                <Button
                    variant="no-hover"
                    bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
                    w={{sm: "240px", md: "100%", lg: "240px"}}
                    h="50px"
                    mx={{sm: "auto", md: "0px"}}
                    color="#fff"
                    fontSize="xs"
                    fontWeight="bold"
                >
                  <a href={"/#/support"}>
                    Download Manual
                  </a>
                </Button>
                &nbsp;
                <Button
                    variant="no-hover"
                    bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
                    w={{sm: "240px", md: "100%", lg: "240px"}}
                    h="50px"
                    mx={{sm: "auto", md: "0px"}}
                    color="#fff"
                    fontSize="xs"
                    fontWeight="bold"
                >
                  <a href="/software/Setup_IPManager_v2.1.0.8.exe.xz" download>
                    Download Ip Manager
                  </a>
                </Button>
              </Flex>
            </Flex>
          </Flex>

          <Text color={textColor} fontSize="lg" fontWeight="bold">
            Product Spec
          </Text>

          <Box w="100%" overflowX={{ sm: "scroll", lg: "hidden" }}>
            <Table variant="simple" w="100%">
              <Thead>
                <Tr>
                  <Th color="gray.400" fontSize="xs">
                    Feature
                  </Th>
                  <Th color="gray.400" fontSize="xs">
                    UTC-8060R
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">MODEL</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold"></Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">유효화소</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">3840(H) x 2160(V)</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">촬상소자</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">1/2.5" 8M Pixels Progressive Exmor Sensor</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">최소거리</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">100mm~1200mm</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">S/N비</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">54dB</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">최저조도</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">주간: 0.01Lux, 야간: 0 Lux (IR LED ON)</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">초점 거리</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">F-3.6mm</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">초점 제어</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">고정</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">해상도</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">2592X1944(20fps), 2688x1520(30fps), 1920x1080(30fps)</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">LSC(Lens Shading Correction)</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">고정</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">OSD</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">지원</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Flip/Mirror</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Adjust</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">노출(AE)</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Adjust</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">셔터속도</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">1/10000 ~ 1/30 (sec)</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">자동 조리개</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">고정</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Auto Flicker-less</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Adjust</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">색 온도(AWB)</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">자동(실내,실외), 그늘, 쾌청한 하늘, 형광등, 백열등, 불꽃, 수동</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Day & Night</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">자동/주간/야간/외부신호 _고정</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">AE,BLC,HLC,DNR</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Adjust</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">안개보정(Defog)</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Adjust</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">사생활 보호(Privacy Mask)</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Adjust</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">움직임 감지(Motion Detection)</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Adjust</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">적외선 LED</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">SUPER IR 6EA</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">영상출력</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">AHD (TVI/CVI/CVBS)</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">전원 입력</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">DC JACK</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">영상출력</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">BNC / CVBS (옵션)</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">EXT OSD</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">OSD KEY (옵션)</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">동작온도/습도</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">-20°C ~ 50°C / 90% RH 이하</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">조절</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">3축 조절기능</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">사용전원/소비전류</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">DC12V / 510mA(Max)</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">중량</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">1Kg</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">외 형 치 수</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">84mm(W) X84mm(H)X282 mm(L)</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">방진/방수</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Y</Text> </Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">충격대응</Text> </Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Y</Text> </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>

          <br/>

          <Text color={textColor} fontSize="lg" fontWeight="bold">
            Other Products
          </Text>

          <Box w="100%" overflowX={{ sm: "scroll", lg: "hidden" }}>
            <Table variant="simple" w="100%">
              <Thead>
                <Tr>
                  <Th color="gray.400" fontSize="xs">
                    Model Name
                  </Th>
                  <Th color="gray.400" fontSize="xs">
                  </Th>
                  <Th color="gray.400" fontSize="xs">
                  </Th>
                  <Th color="gray.400" fontSize="xs">
                  </Th>
                  <Th color="gray.400" fontSize="xs">
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td minW="300px">
                    <Flex align="center">
                      <Box w="40px" h="40px" me="14px">
                        <Image
                            src={tncBz236v12afImg}
                            w="100%"
                            h="100%"
                            borderRadius="12px"
                        />
                      </Box>
                      <a href="/#/product/bullet/tnc-bz236v12af">
                      <Text color={textColor} fontSize="sm" fontWeight="bold">
                        TNC-BZ236V12AF
                      </Text>
                      </a>
                    </Flex>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      Network
                    </Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      IR
                    </Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      2M
                    </Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td minW="300px">
                    <Flex align="center">
                      <Box w="40px" h="40px" me="14px">
                        <Image
                            src={tncBz204v12afsImg}
                            w="100%"
                            h="100%"
                            borderRadius="12px"
                        />
                      </Box>
                      <a href="/#/product/bullet/tnc-bz204v12afs">
                        <Text color={textColor} fontSize="sm" fontWeight="bold">
                          TNC-BZ204V12AFS
                        </Text>
                      </a>
                    </Flex>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      Network
                    </Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      IR
                    </Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      2M
                    </Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td minW="300px">
                    <Flex align="center">
                      <Box w="40px" h="40px" me="14px">
                        <Image
                            src={tncB210v12afsImg}
                            w="100%"
                            h="100%"
                            borderRadius="12px"
                        />
                      </Box>
                      <a href="/#/product/bullet/tnc-b210v12afs">
                        <Text color={textColor} fontSize="sm" fontWeight="bold">
                          TNC-B210V12AFS
                        </Text>
                      </a>
                    </Flex>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      Network
                    </Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      IR
                    </Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      2M
                    </Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td minW="300px">
                    <Flex align="center">
                      <Box w="40px" h="40px" me="14px">
                        <Image
                            src={tncB210v12afsImg}
                            w="100%"
                            h="100%"
                            borderRadius="12px"
                        />
                      </Box>
                      <a href="/#/product/bullet/tnc-b510v12af">
                        <Text color={textColor} fontSize="sm" fontWeight="bold">
                          TNC-B510v12af
                        </Text>
                      </a>
                    </Flex>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      Network
                    </Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      IR
                    </Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      5M
                    </Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td minW="300px">
                    <Flex align="center">
                      <Box w="40px" h="40px" me="14px">
                        <Image
                            src={tncB217v42af}
                            w="100%"
                            h="100%"
                            borderRadius="12px"
                        />
                      </Box>
                      <a href="/#/product/bullet/tnc-b120v12afs">
                        <Text color={textColor} fontSize="sm" fontWeight="bold">
                          TNC-B120V12AFS
                        </Text>
                      </a>
                    </Flex>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      Network
                    </Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      IR
                    </Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      12M
                    </Text>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        </Flex>
      </CardBody>
    </Card>
  );
}

export default Utc8060r;
