// Chakra imports
import {Box, Flex, Grid, Text, useColorModeValue} from "@chakra-ui/react";
import TgcamBannerImage from "../../../assets/img/banner.png"
import React, { useState } from "react";
import Header from "./components/Header";
import NewProducts from "./components/NewProducts";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";

function Main () {
  // Chakra color mode
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );
    const textColor = useColorModeValue("gray.700", "white");

  return (
    <Flex direction="column">
        <Header
            backgroundHeader={TgcamBannerImage}
            backgroundProfile={bgProfile}
        />

        <br/>
        <Grid
            templateColumns={{ sm: "1fr", md: "1fr 1fr", xl: "repeat(3, 1fr)" }}
            height="200px"
            gap="24px"
            mb={{ lg: "26px" }}
        >
            <Card p='38px 10px 0px 0px' mb={{ sm: "26px", lg: "0px" }} height="200px">
                <CardHeader mb='20px' pl='22px'>
                    <Flex direction='column' alignSelf='flex-start'>
                        <Text fontSize='lg' color={textColor} fontWeight='bold' mb='6px'>
                            Since 2002
                        </Text>
                    </Flex>
                </CardHeader>
                <Box w='100%' h={{ sm: "300px" }} ps='8px'>
                    Our company was established in 2002. We develop and sell a wide range of video security products.
                </Box>
            </Card>

            <Card p='38px 10px 0px 0px' mb={{ sm: "26px", lg: "0px" }} height="200px">
                <CardHeader mb='20px' pl='22px'>
                    <Flex direction='column' alignSelf='flex-start'>
                        <Text fontSize='lg' color={textColor} fontWeight='bold' mb='6px'>
                           IoT
                        </Text>
                    </Flex>
                </CardHeader>
                <Box w='100%' h={{ sm: "100px" }} ps='8px'>
                    We have more than 40 of the IoT models of various performance.
                </Box>
            </Card>

            <Card p='38px 10px 0px 0px' mb={{ sm: "26px", lg: "0px" }} height="200px">
                <CardHeader mb='20px' pl='22px'>
                    <Flex direction='column' alignSelf='flex-start'>
                        <Text fontSize='lg' color={textColor} fontWeight='bold' mb='6px'>
                            Various Products
                        </Text>
                    </Flex>
                </CardHeader>
                <Box w='100%' h={{ sm: "300px" }} ps='8px'>
                    Recognized the our technical strength from many companies, it has sold more than 12,000 units annually of the product.
                </Box>
            </Card>
        </Grid>

        <NewProducts title={"New Product"} description={""} />
    </Flex>
  );
}

export default Main;
