// Chakra imports
import {
    Box,
    Flex,
    Grid, SimpleGrid, Stack, Text, useColorModeValue
} from "@chakra-ui/react";
import BarChart from "../../components/Charts/BarChart";
import LineChart from "../../components/Charts/LineChart";
import Globe from "../../components/Globe/Globe";
// Custom icons
import {
    CartIcon,
    DocumentIcon,
    GlobeIcon, WalletIcon
} from "../../components/Icons/Icons.js";
import React from "react";
import {
    barChartDataDefault,
    barChartOptionsDefault,
    lineChartDataDefault,
    lineChartOptionsDefault
} from "../../variables/charts";
import { salesData } from "../../variables/general";
import Location from "./components/Location";
import MiniStatistics from "./components/MiniStatistics";
import SalesByCountry from "./components/SalesByCountry";
import SalesOverview from "./components/SalesOverview";


export default function Default() {
  // Chakra Color Mode
  const iconTeal = useColorModeValue("teal.300", "teal.300");
  const iconBoxInside = useColorModeValue("white", "white");
  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Text
        color={textColor}
        fontWeight="bold"
        fontSize="3xl"
        mb="30px"
        ps="20px"
      >
        Contact Us
      </Text>

      <Grid
        gap="24px"
        mb={{ lg: "26px" }}
      >
        <Location />
      </Grid>
    </Flex>
  );
}
