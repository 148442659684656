import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import AdminLayout from "layouts/Admin.js";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

ReactDOM.render(
  <HashRouter>
      <ScrollToTop />
      <Switch>
      <Route path={`/`} component={AdminLayout} />
      <Redirect from={`/`} to="/home" />
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);
